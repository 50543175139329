@import "./my_bulma.scss";
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Holtwood+One+SC&family=Lato&family=Montserrat:wght@600&display=swap');

body {
    background-color:white;
}

.navbar-burger {
    background-color: rgb(238, 236, 236);
    border: none;
    background-color: transparent;

}

.masterbatchImage {
    object-fit: contain;
}

.hero {
    background-color: navy;
}
.card {
    max-width: 100%;
    position: center;
}

.has-bg-img { background: url("https://i.ibb.co/cXGt9xW/jj-ying-7-JX0-bfiux-Q-unsplash-4.jpg")center center; background-size:cover; }

.has-bg-img2 { background: url("https://i.ibb.co/kyGnT6c/white-elegant.jpg")bottom left; background-size: cover; }

.aditivos-hero-one { 
    background: url("https://i.ibb.co/W6N2FJn/mohan-murugesan-a-PUi7w4k-Kb-I-unsplash.jpg") center center; background-size: cover; 
    font-family: 'Bebas Neue', cursive;

}

.bio-hero {
    background: url("https://i.ibb.co/n0TnmtS/Green-Soil.jpg") center center; background-size: cover; 
    font-family: 'Bebas Neue', cursive;
}

.compostos-hero {
    background: url("https://i.ibb.co/qMyV1T0/pvcpipes.jpg") center center; background-size: cover; 
    font-family: 'Bebas Neue', cursive;
}

.masterbatches-hero {
    background: url("https://i.ibb.co/F7PxvHd/Inicio-Image.jpg") center center; background-size: cover; 
    font-family: 'Bebas Neue', cursive;
}

.resinas-hero {
    background: url("https://i.ibb.co/8cF1xLw/White-Resin.jpg") center center; background-size: cover; 
    font-family: 'Bebas Neue', cursive;
}

.third-carousel-homepage {
    background: url("https://i.ibb.co/n8zgT7X/shutterstock-604941308.jpg") center center; background-size: cover; 
    font-family: 'Bebas Neue', cursive;
}

span {
    text-align: center;    
}



.produtos-font {
    color: white;
    font-size: 45px;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 2px 2px 5px black;
}

.produtos-hero {
    background-color: lightgrey;

}

.color-container {
    background-color: #F4F4F4;
}



.title.beplast-hero {
   color: white;
   font-size: 45px;
   font-family: 'Montserrat', sans-serif;
   text-shadow: 2px 2px 5px black;
}

.beplast-hero-one {
    background-color: navy;
   
}


.world-pic {
    background-size: cover; 
}

.inicio-hero-one {
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-shadow: 2px 2px 5px navy;
}

.subtitle-description {
    font-size: 34px;
    color: gray;
    // font-family: 'Montserrat', sans-serif;
    text-shadow: 2px 2px 5px white;
}

.inicial-hero-homepage {
    background: url("https://i.ibb.co/9sDSyv0/shutterstock-1007708014.jpg") center center; background-size: cover; 

}

.carousel-inicio {
    background: url("https://i.ibb.co/cXGt9xW/jj-ying-7-JX0-bfiux-Q-unsplash-4.jpg") center center; background-size: cover; 
}

.scroll-to-top {
    float: right;
    margin: 35px;
}



.control-arrow-control-prev {
    color: black;
}

.contato-color {
    color: navy
}

a span {
    color: blue;
}

.boldText {
    font-weight: bold;
}


// @media only screen and (min-width: 768px) {  .brnodisplay {     display: none; }}



$card-header-padding: 1.75rem 1rem !default


